<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="6" md="6">DEVOLUCIÓN</v-col>
                    <v-col cols="6" md="6">
                        <v-row class="mt-2 mr-2" justify="end">
                            <span class="text-caption">Suc.:<strong class="text-body-2 ml-2">{{txtSucursal}}</strong></span>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-radio-group v-model="docDTE" row>
                                    <v-radio @click="newVenta()" label="Boleta" color="success" value="22"></v-radio>
                                    <v-radio @click="newVenta()" label="Factura" color="success" value="5"></v-radio>
                                    <v-radio @click="newVenta()" label="NC" color="success" value="2"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="orden" :counter="15" label="Numero Venta" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn @click="verDTE(orden)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="rut" label="Rut Cliente" dense outlined disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="razon" label="Nombre" dense outlined disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn v-if="ntipoDte==22" class="mt-1" @click="dialogo_cliente=true" small color="teal" dark rounded><v-icon small>streetview</v-icon></v-btn>
                                <v-btn v-else class="mt-1" small color="grey" dark rounded><v-icon small>streetview</v-icon></v-btn>
                            </v-col>
                            <!-- <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="ndev" label="Orden" dense outlined filled disabled></v-text-field>
                            </v-col> -->
                        </v-row>
                        <v-divider width="0"></v-divider>
                        <v-row>
                            <v-col cols="12" md="9">
                                <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                    <template v-slot:item.cantidad="{ item }">
                                        <span small>{{ item.cantidad }}</span>
                                        <v-icon class="ml-1 red--text" @click="menos(item)">arrow_drop_down</v-icon>
                                    </template>
                                    <template v-slot:item.accion="{ item }">
                                        <v-icon small class="red--text" @click="deleteItem(item)">delete</v-icon>
                                    </template>
                                </v-data-table>
                                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="3">
                                <div style="margin-top:-8%"><span class="text-caption">&nbsp;Desc.{{pordescuento}}%</span></div>
                                <v-alert class='text-caption' dense text border="left" color="grey">Afecto ($):{{neto.toLocaleString()}}</v-alert> 
                                <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): {{iva}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="red">Total ($): {{total.toLocaleString()}}</v-alert>
                            </v-col>
                            <v-col cols="6" md="1">
                                <v-text-field class='text-caption' v-model="totaldev" label="Dev" disabled filled dense></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select class='text-caption' @change="alertaFolios(dte)" v-model="dte" :items="itemDTE" label="Selecciona DTE" item-text="doc" item-value="id"></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select class='ml-2 text-caption' @change="addForm(pago)" v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" item-value="id"></v-select>
                            </v-col>
                              <v-col cols="12" md="3">
                                <v-select class='ml-2 text-caption' @change="addMotivo(motivo)" v-model="motivo" :items="itemMOTIVO" label="Motivo" item-text="motivo" item-value="id"></v-select>
                                <!-- <v-text-field class='text-caption' v-model="envio" label="Envio Digital" dense filled></v-text-field> -->
                            </v-col>
                            <v-col cols="12" md="4">
                                <div class="mt-5 text-right">
                                    <v-btn @click="newVenta()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                    <v-btn v-if="btnGrabar===true" @click="inDTE(dte,pago)" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                                    <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                                    <a v-if="btnPrt===true" target="_blank" :href="html" class="ml-2"><v-icon color="teal">print</v-icon></a>
                                    <a v-else href="javascript:void(0)" class="ml-2"><v-icon class="grey--text">print</v-icon></a> 
                                    <!-- <a  class="ml-2" v-if="descargaCSV===false" href="javascript:void(0)" @click="fileCSV()"><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></a>
                                    <a  class="ml-2" v-else :href="rutaCSV"><v-icon color="teal">cloud_download</v-icon></a> -->
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>
        </v-card>
        <!-- Dialogo Clientes -->
        <v-dialog v-model="dialogo_cliente" transition="dialog-bottom-transition" max-width="700" persistent>
            <v-card>
                <v-card-title>
                    <v-btn @click="dialogo_cliente=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="busca_cliente" label="Buscar" dense required></v-text-field>
                </v-card-title>
                <v-card-text>
                    <v-data-table @click:row="fila_cliente" dense dark :search="busca_cliente" :headers="head_cliente" :items="items_cliente" :page.sync="page_cliente" :items-per-page="PerPage_cliente"  @page-count="pageCount_cliente = $event" hide-default-footer class="elevation-1 text-size">
                    </v-data-table>
                    <v-pagination v-model="page_cliente" :length="pageCount_cliente"  :total-visible="5"></v-pagination>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Dialogo Devolucion Con Boleta -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="700" persistent>
            <form>
            <v-card>
            <v-toolbar color="primary" dark>Cliente Devolución Boleta</v-toolbar>
            <v-card-text class="mt-5">
                <v-row>                    
                     <v-col cols="12" md="4">
                        <v-text-field class='text-caption' v-model="dev_rut" label="Rut" outlined dense></v-text-field>
                     </v-col>    
                     <v-col cols="12" md="4">
                        <v-text-field class='text-caption' v-model="dev_nombre" label="Nombre" outlined dense></v-text-field>
                     </v-col>    
                     <v-col cols="12" md="4">
                        <v-text-field class='text-caption' v-model="dev_apellido" label="Apellido" outlined dense></v-text-field>
                     </v-col>    
                     <v-col cols="12" md="6">
                        <v-text-field class='text-caption' v-model="dev_direccion" label="Direccion" outlined dense></v-text-field>
                     </v-col>    
                     <v-col cols="12" md="3">
                        <v-text-field class='text-caption' v-model="dev_comuna" label="Comuna" outlined dense></v-text-field>
                     </v-col>    
                     <v-col cols="12" md="3">
                        <v-text-field class='text-caption' v-model="dev_ciudad" label="Ciudad" outlined dense></v-text-field>
                     </v-col>    
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="salirDialog()" color="indigo">Salir</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog()" color="indigo">Enviar</v-btn>
            </v-card-actions>
          </v-card>
          </form>
        </v-dialog>
        <!-- DIALOGO CHEQUE -->
        <v-dialog v-model="dialogCheque" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>CHEQUES
                <v-spacer></v-spacer>
                <v-btn @click="dialogCheque=false" small text fab><v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="fechaCheque" label="Fecha" prepend-icon="event" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaCheque" @input="menu1 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                   <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="rutCheque" label="Rut" placeholder="99999999-K" outlined dense required filled disabled></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="7">
                        <v-text-field class='text-caption' v-model="bancoCheque" label="Banco" outlined dense required filled disabled></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="cuentaCheque" label="N° Cuenta" outlined dense required filled disabled></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="numeroCheque" label="N° Cheque" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="montoCheque" label="Monto" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn @click="dialogCheque=false" class="mt-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>              
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- dialog alerta folios --> 
        <v-dialog v-model="dialogFolios" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialogFolios=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <div class="d-flex justify-center">
                <h3>Quedan <strong class="text-h5 red--text">( {{countfolios}} )</strong> Folios Disponibles</h3>
                </div>
            </v-card-text>
        </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Devolucion', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledOrden:false,
            filledOrden:false,
            btnGrabar:false,
            btnPrt:false,
            html:'',
            orden:'',
            ndev:'0',
            totaldev:'0',
            entidad:'',
            rut:'',
            razon:'',
            direccion:'',
            comuna:'',
            ciudad:'',
            giro:'',
            fecha:'',
            hoy:'',
            neto:0,
            impuesto:'',
            iva:0,
            total:0,
            dte:'',
            pago:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Codigo', align: 'start', sortable: true, value: 'codigo' },
                { text: 'Producto', align: 'start', sortable: false, value: 'producto' },
                { text: 'Precio', align: 'center', sortable: false, value: 'precio' },
                { text: 'cantidad', align: 'center', sortable: false, value: 'cantidad' },
                { text: 'Bodega', align: 'center', sortable: false, value: 'bodega' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            items: [],
            itemDTE:[
                {id:'2',doc:'Nota Credito'},
                {id:'17',doc:'Nota Debito'},
            ],
            itemPAGO:[
                {id:'1',forma:'Efectivo'},
                {id:'8',forma:'Transferencia'},
                {id:'10',forma:'Cheque'}, // opciones nuevas para devoluciones
                {id:'20',forma:'Credito'} // opciones nuevas para devoluciones
            ],
            OutID:0,  
            descargaCSV:false,
            rutaCSV:'',
            envio:'',
            nSucursal:0,
            txtSucursal:'',
            ntipoDte:0,
            pordescuento:0,
            subtotal:0,
            fecharef:'',

            dialog:false,
            dev_entidad:0,
            dev_rut:'',
            dev_nombre:'',
            dev_apellido:'',
            dev_direccion:'',
            dev_comuna:'',
            dev_ciudad:'',
            docDTE:'22',

            //datos Cheque
            dialogCheque:false,
            fechaCheque: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu1:false,
            rutCheque:'85161300-5',
            bancoCheque:2,
            cuentaCheque:'1710181804',
            numeroCheque:'',
            montoCheque:'',
            motivo:'',
            itemMOTIVO:[
                {id:'1',motivo:'Anula Documento'},
                {id:'2',motivo:'Corrige texto Documento'},
                {id:'3',motivo:'Corrige Montos'}
            ],
            numMotivo:0,
            txtMotivo:'',
            // dialogo cliente
            dialogo_cliente:false,
            busca_cliente:'',
            page_cliente: 1,
            pageCount_cliente: 0,
            PerPage_cliente: 10,
            head_cliente: [
                { text: 'Rut', align: 'center', sortable: true, value: 'Rut' },
                { text: 'Nombre', align: 'start', sortable: true, value: 'Nombre' },
            ],
            items_cliente: [],
            pesosdescuento:0,
            //control de Folios
            dialogFolios:false,
            countfolios:0,
        }
    },
    mounted(){
        this.newVenta();
        this.buscarSucursal();
        this.search_cliente();
    },
    methods:{
        async alertaFolios(tpdoc){
            //console.log(tpdoc);
            var codSII=0;
            switch (tpdoc) {
                case '2':
                        codSII=61
                break;
                case '17':
                        codSII=56
                break;
            }
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/alerta/folios/'+this.contrato+'/'+codSII);
            const datos=response.data;
            console.log(datos);
            if (datos[0].existe=='si'){
                if (datos[0].Cuenta<=10){
                    //activa alerta
                    this.countfolios=datos[0].Cuenta;
                    this.dialogFolios=true;
                }
            };
        },
        async search_cliente(){
            try {
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/lista/clientes/'+this.contrato);
                const datos=response.data;
                //console.log(datos);
                this.items_cliente=[];
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        this.items_cliente.push(element);
                    });
                }                
            } catch (error) {
                console.log(error)
            }
        },
        fila_cliente(fila){
            console.log(fila);
            this.dev_entidad=fila.ID;
            this.dev_rut=fila.Rut;
            this.dev_nombre=fila.Nombre;
            //this.dev_apellido=fila.Rut;
            this.dev_direccion=fila.Direccion;
            this.dev_comuna=fila.Comuna;
            this.dev_ciudad=fila.Ciudad;
            // datos para NC
            this.entidad=this.dev_entidad;
            this.rut=this.dev_rut;
            this.razon=this.dev_nombre;
            this.dialogo_cliente=false;
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        salirDialog(){
            this.rut='';
            this.dev_rut='';
            this.dev_nombre='';
            this.dev_apellido='';
            this.dev_direccion='';
            this.dev_comuna='';
            this.dev_ciudad='';
            this.dialog=false;
        },
        closeDialog(){            
            if(this.dev_entidad!=0 && this.dev_rut.trim()!='' &&
               this.dev_nombre.trim()!='' &&
               this.dev_apellido.trim()!='' &&
               this.dev_direccion.trim()!='' &&
               this.dev_comuna.trim()!='' &&
               this.dev_ciudad.trim()!=''){
                let ruts=this.validaRut(this.dev_rut);
                if (ruts){
                    this.rut=this.dev_rut;
                    this.razon=this.dev_nombre+' '+ this.dev_apellido;
                    this.dialog=false;                    
                } else {
                    this.Msg='Rut no Valido'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Datos Obligatorios'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        validaRut(rutCompleto){
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			    return false;
		    var tmp 	= rutCompleto.split('-');
		    var digv	= tmp[1]; 
		    var ruti 	= tmp[0];
		    if ( digv == 'K' ) digv = 'k' ;
		    return (this.dv(ruti) == digv );
        },
        dv(T){
            var M=0,S=1;
    		for(;T;T=Math.floor(T/10))
	    		S=(S+T%10*(9-M++%6))%11;
		    return S?S-1:'k';
        },
        async buscarSucursal(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+this.contrato+'/'+this.usu);
            const datos=response.data;
            if (datos[0].existe=='si'){
                if (datos[0].Sucursal!=0){
                    this.nSucursal=datos[0].Sucursal;
                    const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sucursales/'+this.contrato);
                    const datos1=response1.data;
                    if (datos1[0].existe=='si'){
                        datos1.forEach(element => {
                            if(element.ID==this.nSucursal){
                                this.txtSucursal=element.Nombre                            
                            }
                        });
                    } else {  
                        this.txtSucursal='Sin Sucursal';
                        this.nSucursal=0;
                    }
                } else {
                    this.txtSucursal='Sin Sucursal';
                    this.nSucursal=0;
                }
            } else { 
                this.txtSucursal='Sin Sucursal';
                this.nSucursal=0;
            }
        },
        async fileCSV(){
            if (this.btnPrt===true){
                this.loading=true;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/fileCsv/dev/'+this.contrato+'/'+this.OutID+'/'+this.entidad);
                const datos=response.data;
                //console.log(datos);
                if (datos=='si'){
                    this.descargaCSV=true;
                    this.rutaCSV='https://www.transervi.cl/Api_TranServi/public/buzon/'+this.contrato+'/'+this.OutID+'.csv';
                }
                this.loading=false;
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.entidad='';
            this.ndev='0';
            this.totaldev='0';
            this.rut='';
            this.razon='';
            this.direccion='';
            this.comuna='';
            this.ciudad='';
            this.giro='';
            this.fecha='';
            this.hoy='';
            this.pesosdescuento=0;
            this.neto=0;
            this.iva=0;
            this.total=0;
            this.impuesto='';
            this.dte='';
            this.pago='';
            this.items=[];
            this.btnPrt=false;
            this.html='';
            this.OutID=0;
            this.descargaCSV=false;
            this.rutaCSV='';  
            this.envio='';
            this.ntipoDte=0; 
            this.pordescuento=0;
            this.subtotal=0;
            this.fecharef='';
            this.dev_entidad=0;
            this.dev_rut='';
            this.dev_nombre='';
            this.dev_apellido='';
            this.dev_direccion='';
            this.dev_comuna='';
            this.dev_ciudad='';
            //limpiar cheque
            this.fechaCheque=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.numeroCheque='';
            this.montoCheque='';
            this.motivo='';
            this.numMotivo=0;
            this.txtMotivo='';
        },
        newVenta(){
            this.orden='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.btnGrabar=false;
            this.clsInput();
            this.muestraOpcion();
        },
        muestraOpcion(){
            this.itemDTE=[];
            this.itemMOTIVO=[];
            if(parseInt(this.docDTE)!=2){
                this.itemDTE.push( {id:'2',doc:'Nota Credito'} );
                this.itemMOTIVO=[
                    {id:'1',motivo:'Anula Documento'},
                    {id:'2',motivo:'Corrige texto Documento'},
                    {id:'3',motivo:'Corrige Montos'}
                ]
        } else {
                this.itemDTE.push( {id:'17',doc:'Nota Debito'} )
                this.itemMOTIVO=[
                    {id:'1',motivo:'Anula Documento'},
                ]
            }
        },
        async verDTE(nOrden){
            if (this.orden!=''){
                this.clsInput();
                this.loading=true;
                let consulta;
                if(parseInt(this.docDTE)==2){
                    consulta=await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/DTE/dev/NC/'+this.contrato+'/'+nOrden+'/'+this.docDTE);
                } else {
                    consulta = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/DTE/dev/'+this.contrato+'/'+nOrden+'/'+this.docDTE);
                };
                const datos=consulta.data;
                //console.log(datos);
                if (datos[0].existe=='si'){
                    if(parseInt(this.docDTE)!=2){
                        this.disabledOrden=true;
                        this.filledOrden=true;
                        this.btnGrabar=true;
                        this.ndev = datos[0].Orden;
                        this.totaldev = datos[0].Devolucion;
                        this.entidad=datos[0].Entidad;
                        this.rut=datos[0].Rut;
                        this.razon=datos[0].Nombre;
                        this.direccion=datos[0].Direccion;
                        this.comuna=datos[0].Comuna;
                        this.ciudad=datos[0].Ciudad;
                        this.giro=datos[0].Giro;
                        this.fecha=datos[0].Hoy;
                        this.hoy=datos[0].Hoy;
                        this.impuesto=datos[0].Iva;
                        this.ntipoDte=parseInt(this.docDTE); //datos[0].TipoDte;
                        this.pordescuento=datos[0].Descuento;
                        this.fecharef=datos[0].Fecha;
                        this.items=[];
                        let nTotal=0;
                        datos.forEach(element => {
                            this.items.push({
                                'codigo':element.Sku,
                                'producto':element.Producto,
                                'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                'cantidad':element.Cantidad,
                                'bodega':element.Bodega,
                                'precioitem':element.Precio
                            });
                            nTotal=nTotal+parseInt(element.Total)       
                        });
                        this.subtotal=nTotal;
                        let descuento=Math.round((nTotal*parseFloat(this.pordescuento))/100); 
                        this.pesosdescuento=descuento;           
                        let afecto=nTotal-descuento;
                        let nIva=Math.round((afecto*parseFloat(datos[0].Iva))/100);
                        this.neto=afecto;
                        this.iva=nIva;
                        this.total=afecto+nIva;
                        // if (this.ntipoDte==22){
                        //     this.rut='';
                        //     this.dev_rut='';
                        // }
                    } else {
                        if(parseInt(datos[0].Devolucion)!=0){
                            this.Msg='DTE Anulado'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        } else {
                            this.disabledOrden=true;
                            this.filledOrden=true;
                            this.btnGrabar=true;
                            this.ndev = datos[0].Orden;
                            this.totaldev = datos[0].Devolucion;
                            this.entidad=datos[0].Entidad;
                            this.rut=datos[0].Rut;
                            this.razon=datos[0].Nombre;
                            this.direccion=datos[0].Direccion;
                            this.comuna=datos[0].Comuna;
                            this.ciudad=datos[0].Ciudad;
                            this.giro=datos[0].Giro;
                            this.fecha=datos[0].Hoy;
                            this.hoy=datos[0].Hoy;
                            this.impuesto=datos[0].Iva;
                            this.ntipoDte=parseInt(this.docDTE); //datos[0].TipoDte;
                            this.pordescuento=datos[0].Descuento;
                            this.fecharef=datos[0].Fecha;
                            this.items=[];
                            let nTotal=0;
                            datos.forEach(element => {
                                this.items.push({
                                    'codigo':element.Sku,
                                    'producto':element.Producto,
                                    'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                                    'cantidad':element.Cantidad,
                                    'bodega':element.Bodega,
                                    'precioitem':element.Precio
                                });
                                nTotal=nTotal+parseInt(element.Total)       
                            });
                            this.subtotal=nTotal;
                            let descuento=Math.round((nTotal*parseFloat(this.pordescuento))/100);
                            this.pesosdescuento=descuento;           
                            let afecto=nTotal-descuento;
                            let nIva=Math.round((afecto*parseFloat(datos[0].Iva))/100);
                            this.neto=afecto;
                            this.iva=nIva;
                            this.total=afecto+nIva;
                            // if (this.ntipoDte==22){
                            //     this.rut='';
                            //     this.dev_rut='';
                            // }
                        }
                    }
                } else {
                    this.Msg='DTE no Existe'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
                this.loading=false;
            }
        },
        reCalcular(varMonto){
            let descuento=Math.round((varMonto*parseFloat(this.pordescuento))/100);
            this.pesosdescuento=descuento;            
            let afecto=varMonto-descuento;
            let nIva=Math.round((afecto*parseInt(this.impuesto))/100);
            this.neto=afecto;
            this.iva=nIva;
            this.total=afecto+nIva;
        },
        deleteItem(array){
            // this.items = this.items.filter(function(dat) {
            //     return dat.codigo !== array.codigo; 
            // });
            const varNeto=parseInt(this.subtotal)-(parseInt(array.precioitem)*parseInt(array.cantidad));
            array.cantidad='0';
            this.subtotal=varNeto;
            this.reCalcular(varNeto);
        },
        menos(array){
            if (array.cantidad>0){
                array.cantidad=parseInt(array.cantidad)-1
                const varNeto=parseInt(this.subtotal)-parseInt(array.precioitem);
                this.subtotal=varNeto;
                this.reCalcular(varNeto);
            }
        },
        async inDTE(selectDTE,selectPago){
            let continua='si';
            if(selectPago==10){
                if(this.montoCheque=='' || this.numeroCheque==''){
                        this.Msg='Forma de Pago incompleta'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        continua='no'
                } else {
                    const existencia = await this.validarCheques();
                    if (existencia){
                        this.Msg='Cheque Existente'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        continua='no'
                    }
                }
            }
            if(continua=='si'){
                if(this.nSucursal!=0){
                    if (this.items.length!=0 && parseInt(this.total)!=0 && this.orden!=''){
                        if (selectDTE!=0 && selectPago!=0 && this.numMotivo!=0){
                            this.loading=true;
                            // obtenemos el token del contrato
                            let varApiKey='';
                            let varU='';
                            let varP='';
                            let varIdEmpresa='';
                            let numDocumento=0; // 0 folio automatico -- NC: 51-501
                            // tipo de Venta
                            let TpDocumento=''; // Nota de Credito Electronica
                            if(selectDTE!=2){ TpDocumento='56' } // Nota Debito (17)
                            else { TpDocumento='61' }; // Nota Credito (2)
                            //forma de Pago solo 2 formas credito o contado
                            let vformaPago='';
                            if(selectPago==20){
                                vformaPago='CREDITO';
                            } else {
                                vformaPago='CONTADO';
                            };
                            // obtener folio
                            const resFolio = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/folio/nuevo/'+this.contrato+'/'+TpDocumento);
                            const datoFolio = resFolio.data;
                            console.log(datoFolio);
                            if (datoFolio[0].existe=='si'){
                                numDocumento=datoFolio[0].Folio;
                                // reservo folio
                                const parFolio={ 'numero':numDocumento,'estado':'P' };
                                const updateFolio = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/update/folio/'+this.contrato+'/'+TpDocumento,parFolio);
                                const dataFolio = updateFolio.data;
                                if (dataFolio=='si'){
                                    //token
                                    const resToken = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/apikey/access/'+this.contrato);
                                    const respuesta = resToken.data;
                                    console.log(respuesta);
                                    if (respuesta[0].existe=='si'){ 
                                        varApiKey=respuesta[0].Token;
                                        varU=respuesta[0].U;
                                        varP=respuesta[0].P;
                                        varIdEmpresa=respuesta[0].IdEmpresa;
                                        // completar formData
                                        let InstFormData = new FormData();
                                        InstFormData.append('fchemis' , this.hoy);
                                        //InstFormData.append('nrocaja' , '2');
                                        //InstFormData.append('mntexe' , '0');
                                        //InstFormData.append('mntbruto' , '0');
                                    
                                        //InstFormData.append('tasaimp' , '0.00');
                                        //InstFormData.append('nrotienda' , '1');
                                        InstFormData.append('rutrecep' , this.rut);
                                        InstFormData.append('rznsocrecep' , this.razon);
                                        InstFormData.append('dirrecep' , this.direccion);
                                        InstFormData.append('cmnarecep' , this.comuna); 
                                        InstFormData.append('ciudadrecep' , this.ciudad);
                                        InstFormData.append('girorecep' , this.giro);
                                        InstFormData.append('idempresa' , varIdEmpresa);
                                        InstFormData.append('folioref1' , this.orden);
                                        InstFormData.append('fchref1' , this.fecharef);
                                        if (this.ntipoDte==22) { //boleta
                                            InstFormData.append('tpodocref1' , '39');
                                        }
                                        if (this.ntipoDte==5) { //factura
                                            InstFormData.append('tpodocref1' , '33');
                                        }
                                        if (this.ntipoDte==2) { //NC
                                            InstFormData.append('tpodocref1' , '61');
                                        }
                                        //InstFormData.append('mntimp' , '0');
                                        InstFormData.append('formapago' , vformaPago); // selectPago
                                        // recorrer items
                                        let reg=1;
                                        this.items.forEach(element => {
                                            if(element.cantidad!=0){
                                                InstFormData.append('indexe'+reg , 0);
                                                InstFormData.append('vlrcodigo'+reg , element.codigo);
                                                InstFormData.append('tpocodigo'+reg , 'Sku');
                                                InstFormData.append('nmbitem'+reg , element.producto);
                                                //InstFormData.append('dscitem1' , 'Descripción');
                                                InstFormData.append('unmditem'+reg , 'UN');
                                                InstFormData.append('qtyitem'+reg , element.cantidad);
                                                InstFormData.append('prcitem'+reg , element.precioitem);                                
                                                // InstFormData.append('descuentopct'+reg , '1.20');
                                                // InstFormData.append('descuentomonto'+reg , '1200');
                                                InstFormData.append('montoitem'+reg , parseInt(element.cantidad)*parseInt(element.precioitem)); 
                                                reg ++;
                                            }
                                        });
                                        InstFormData.append('preview' , '0');
                                        InstFormData.append('rutemisor' , varP);
                                        InstFormData.append('decodeutf8' , 1);
                                        InstFormData.append('apikey' , varApiKey);
                                        InstFormData.append('u' , varU);
                                        InstFormData.append('p' , varP);

                                        InstFormData.append('adt_nombre1' , 'Descuento');
                                        InstFormData.append('adt_valor1' , this.pesosdescuento);
                                        InstFormData.append('adt_nombre2' , 'SubTotal');
                                        InstFormData.append('adt_valor2' , this.subtotal);

                                        InstFormData.append('adt_nombre3' , 'FormaPago');
                                        InstFormData.append('adt_valor3' , vformaPago);
                                        InstFormData.append('adt_nombre4' , 'NmbVendedor');
                                        InstFormData.append('adt_valor4' , this.usu);
                                        // x 2 copias
                                        InstFormData.append('adt_nombre5' , 'Plantilla');
                                        InstFormData.append('adt_valor5' , 'x2_dte_tpl');

                                        InstFormData.append('codref1' , this.numMotivo);
                                        InstFormData.append('razonref1' , this.txtMotivo);

                                        // InstFormData.append('tpomov1' , 'D');
                                        // InstFormData.append('glosadr1' , 'Descuento');
                                        // InstFormData.append('tpovalor1' , '%');
                                        // InstFormData.append('valordr1' , '10');
                                        // InstFormData.append('indexedr1' , '2');

                                        InstFormData.append('mntneto' , this.neto);
                                        InstFormData.append('iva' , this.iva);                            
                                        InstFormData.append('tasaiva' , this.impuesto);
                                        InstFormData.append('mnttotal' , this.total);
                                        //InstFormData.append('mediopago' , 'CONTADO');
                                        //InstFormData.append('fchvenc' , vence);
                                        InstFormData.append('folio' , numDocumento);  
                                        InstFormData.append('tipodte' , TpDocumento);

                                        //descuento global
                                        if(parseFloat(this.pesosdescuento)!=0){
                                            InstFormData.append('tpomov1' , 'D');  
                                            InstFormData.append('glosadr1' , 'Descuento Global');
                                            InstFormData.append('tpovalor1' , '$');  
                                            InstFormData.append('valordr1' , this.pesosdescuento);
                                            InstFormData.append('indexedr1' , 0);
                                        };

                                        let resDTE =[];  
                                        const response= await axios.post('http://dte.transervi.cl/ws_aces/generarDTE/', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                                        resDTE=response.data;
                                        console.log(resDTE);
                                        if (resDTE.mensaje!='OK'){
                                            this.Msg='DTE no enviado'
                                            this.msgbox=true;
                                            this.color='red';
                                            this.showSnack();
                                            //devolvemos el folio estado V
                                            const parFolio1={ 'numero':numDocumento,'estado':'V' };
                                            const updateFolio1 = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/update/folio/'+this.contrato+'/'+TpDocumento,parFolio1);
                                            const dataFolio1 = updateFolio1.data;
                                            if (dataFolio1=='si'){ console.log('folio devuelto') }
                                        } else {
                                            //this.envioDTE(this.envio,resDTE.urlPdf,resDTE.urlXml);
                                            //generamos codigo de referencia
                                            var ref_dev='';
                                            if(parseInt(this.docDTE)==22){ ref_dev='BO '+ this.orden};
                                            if(parseInt(this.docDTE)==5){ ref_dev='FA '+ this.orden };
                                            if(parseInt(this.docDTE)==2){ ref_dev='NC '+ this.orden };
                                            numDocumento=resDTE.folio;
                                            this.html=resDTE.url;
                                            this.btnPrt=true;
                                            this.btnGrabar=false;
                                            this.OutID=0;
                                            this.OutID=numDocumento;                    
                                            const paramsDTE={
                                                'contrato':this.contrato,
                                                'numero':numDocumento,
                                                'tipoDte':selectDTE,
                                                'tipoPago':selectPago,
                                                'neto':this.neto,
                                                'iva':this.iva,
                                                'total':this.total,
                                                'fecha':this.hoy,
                                                'orden':this.ndev,
                                                'oc':'',
                                                'rutaPDF':resDTE.url,
                                                'rutaXML':resDTE.url,
                                                'usuario':this.usu,
                                                'sucursal':this.nSucursal,
                                                'referencia':ref_dev
                                            };                    
                                            await this.saveDTE(paramsDTE);
                                            await this.saveCheque(numDocumento,selectDTE);
                                            //cambiamos el estado del folio exitoso
                                            const parFolio2={ 'numero':numDocumento,'estado':'N' };
                                            const updateFolio2 = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/update/folio/'+this.contrato+'/'+TpDocumento,parFolio2);
                                            const dataFolio2 = updateFolio2.data;
                                            if (dataFolio2=='si'){ console.log('folio exitoso') }
                                        }
                                    } else { 
                                        this.Msg='Token Invalido'
                                        this.msgbox=true;
                                        this.color='red';
                                        this.showSnack();
                                    }                                    
                                } else {
                                    this.Msg='Folio no reservado'
                                    this.msgbox=true;
                                    this.color='red';
                                    this.showSnack();
                                }
                            } else {
                                this.Msg='Sin folio para DTE'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                        } else {
                            this.Msg='Seleccione DTE y Pago'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                        this.loading=false;
                    } else {
                        this.Msg='Sin Datos para DTE'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                } else {
                    this.Msg='Devolucion Sin Sucursal'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            }
        },
        async saveDTE(parametrosDTE){
            const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/DTE/nueva',parametrosDTE);
            const datos=response.data;
            if (datos=='si'){ 
                await this.saveDetalle();
                const ttdev=parseInt(this.totaldev)+1;
                this.totaldev=ttdev.toString();
            } else {
                this.Msg='DTE enviado sin registro Local'
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            }
        },
        async saveDetalle(){
            let t=0;
            if (this.items.length!=0) {
                let tpcv='';
                if(parseInt(this.docDTE)!=2){
                    tpcv='D'
                } else {
                    tpcv='T'
                }
                for (var i = 0; i < this.items.length; i++) {
                    var parametros2={
                        'contrato': this.contrato,
                        'orden': this.ndev,
                        'entidad': this.entidad,
                        'tipo': 'Neto',
                        'cv': tpcv,
                        'sku':this.items[i].codigo,
                        'producto':this.items[i].producto,
                        'precio':this.items[i].precioitem,
                        'cantidad':this.items[i].cantidad,
                        'descuento':this.pordescuento,
                        'bodega': this.items[i].bodega,
                        'observa':'',
                        'usuario':this.usu,
                        'referencia':this.OutID};
                    const response2 = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/compraventa/nueva',parametros2);
                    const datos2=response2.data;
                    //console.log(datos2);
                    if (datos2=='si'){ t++ }
                };
                if (t!=this.items.length) {
                    this.Msg='DTE enviado sin Detalle local';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                } else {
                    await this.UpdateStock();                    
                }
            }
        },
        async UpdateStock(){
            let t=0;
            if (this.items.length!=0) {
                let signos='';
                if(parseInt(this.docDTE)!=2){
                    signos='0+'
                } else {
                    signos='0-'
                }
                for (var i = 0; i < this.items.length; i++) {
                    var parametros1={
                            'contrato': this.contrato,
                            'sku': this.items[i].codigo,
                            'cantidad': this.items[i].cantidad,
                            'signo': signos,
                            'bodega': this.items[i].bodega,
                            'usuario': this.usu,
                            'observa': '',
                            'origen':'Devolucion'};
                    const response1 = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/stock',parametros1);
                    const datos1=response1.data;
                    if (datos1=='si'){ t++ }
                };
                if (t!=this.items.length) {
                    this.Msg='DTE enviado sin actualizar Stock';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                } else {
                    this.btnPrt=true;
                    this.Msg='DTE exitoso';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                }
            }
        },
        async envioDTE(datoEnvio,datoPDF,datoXML){
            if (datoEnvio.trim()!=''){
                let InstFormData = new FormData();
                InstFormData.append('email' , datoEnvio);
                InstFormData.append('pdf' , datoPDF);
                InstFormData.append('xml' , datoXML);
                const response= await axios.post('https://www.transervi.cl/Api_TranServi/public/api/server/sender', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                //console.log(response.data)
            }
        },
        async addForm(p){
            if (p=='10'){
                this.dialogCheque=true
            } else {
                this.fechaCheque=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                this.numeroCheque='';
                this.montoCheque='';
            }
        },
        async saveCheque(numeroDOC,tipoDOC){
            try {
                if (tipoDOC==2){
                    //elimina los cheques de la devolucion
                    await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/pago/cheque/delete/'+this.contrato+'/'+numeroDOC+'/'+tipoDOC);
                    const params={
                        'contrato':this.contrato,
                        'fecha':this.fechaCheque,
                        'rut':this.rutCheque,
                        'banco':this.bancoCheque,
                        'cuenta':this.cuentaCheque,
                        'numero':this.numeroCheque,
                        'monto':this.montoCheque,
                        'movimiento':'Devolucion',
                        'documento':numeroDOC,
                        'tipo':tipoDOC
                    }
                    await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/add/cheque',params);                
                }
            } catch (error) {
                console.log(error)
            }
        },
        async validarCheques(){
            try {
                const datosCheque=this.contrato+'/'+this.rutCheque+'/'+this.bancoCheque+'/'+this.cuentaCheque+'/'+this.numeroCheque;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/search/cheque/'+datosCheque);
                const datos=response.data;
                //console.log(datos);
                if (datos[0].existe!='si'){ 
                    return false
                } else {
                    return true
                }
            } catch (error) {
                console.log(error);
                return true              
            }
        },
        addMotivo(n){
            this.numMotivo=parseInt(n);
            this.txtMotivo=this.itemMOTIVO[n-1].motivo;
            console.log(this.numMotivo,this.txtMotivo);
        }
    }
}
</script>